import React, { useState, useEffect } from 'react';

type Theme = 'light' | 'dark';
type IThemeContext = { theme: Theme; toggleTheme: () => void };

export const ThemeContext = React.createContext<IThemeContext>({} as IThemeContext);

export const ThemeProvider = ({ children }: any) => {
  const [theme, setTheme] = useState<Theme>('dark');
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  useEffect(() => {
    console.log(theme);
    if (theme === 'light') document.body.classList.remove('dark');
    else document.body.classList.add('dark');
  }, [theme]);

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};
