import { useContext } from 'react';
import { ThemeContext } from '../context/ThemeProvider';

export default function DarkModeToggle() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  return (
    <div>
      {theme === 'light' ? (
        <button className='' aria-label='Toggle Dark Mode' type='button' onClick={() => toggleTheme()}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='w-6 h-6 text-indigo-900'
            viewBox='0 0 20 20'
            fill='currentColor'>
            <path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z' />
          </svg>
        </button>
      ) : (
        <button className='' aria-label='Toggle Light Mode' type='button' onClick={() => toggleTheme()}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='w-6 h-6 text-yellow-400'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            strokeWidth={2}>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z'
            />
          </svg>
        </button>
      )}
    </div>
  );
}
