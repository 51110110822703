import React from 'react';

import Header from './components/Header';
import Layout from './components/Layout';
import Landing from './components/Landing';
import Backdrop from './components/Backdrop';
import { ThemeProvider } from './context/ThemeProvider';
function App() {
  return (
    <ThemeProvider>
      <div className='App'>
        <div className='text-gray-700 transition-colors duration-700 ease-in-out bg-gray-200 isolate dark:bg-black dark:text-gray-200'>
          <Layout>
            <Backdrop />
            <Header />
            <Landing />
          </Layout>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
