export default function Backdrop() {
  return (
    <>
      <div className='fixed inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-16rem]'>
        <svg
          className='relative left-[calc(35%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1 rotate-[-170deg] sm:left-[calc(27%-30rem)] sm:h-[40.375rem]'
          viewBox='0 0 1155 678'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            fill='url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)'
            fillOpacity='.8'
            d='M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z'
          />
          <defs>
            <linearGradient
              id='45de2b6b-92d5-4d68-a6a0-9b9b2abad533'
              x1='1155.49'
              x2='-78.208'
              y1='.177'
              y2='474.645'
              gradientUnits='userSpaceOnUse'>
              <stop stopColor='#002199' />
              <stop offset={1} stopColor='#04A1F3' />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className='fixed inset-x-0 top-[calc(90%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(89%-25rem)]'>
        <svg
          className='relative left-[calc(60%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(40%+36rem)] sm:h-[42.375rem]'
          viewBox='0 0 1155 678'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            fill='url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)'
            fillOpacity='.8'
            d='M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z'
          />
          <defs>
            <linearGradient
              id='ecb5b0c9-546c-4772-8c71-4d3f06d544bc'
              x1='1155.49'
              x2='-78.208'
              y1='.177'
              y2='474.645'
              gradientUnits='userSpaceOnUse'>
              <stop stopColor='#002199' />
              <stop offset={1} stopColor='#04A1F3' />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </>
  );
}
