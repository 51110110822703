import DarkModeToggle from './DarkModeToggle';
const logo = require('../logo.png');

export default function Header() {
  return (
    <>
      <header>
        <div className='px-4 py-5 mx-auto sm:px-6 lg:px-8'>
          <div className='flex items-stretch justify-between'>
            <div className='flex-1 md:flex md:items-center md:gap-12'>
              <div className='w-20 h-20 cursor-pointer md:w-28 md:h-28'>
                <a href={'/'}>
                  <img alt='shayan' src={logo} />
                </a>
              </div>
            </div>

            <div className='flex items-center md:gap-8'>
              <nav className='' aria-labelledby='header-navigation'>
                <ul className='flex items-center gap-8 text-lg'></ul>
              </nav>

              <div className='flex items-center gap-4'>
                <div className='sm:flex md:pt-1'>
                  <DarkModeToggle />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
